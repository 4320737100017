(function() {
    'use strict';

    // Create the module and define its dependencies.
    var RegionRevampedModule = angular.module('app.regionRevamped', []);

    /**
     * @class RegionRevampedController
     * @classdesc Controller for region-revamped directive
     */
    var RegionRevampedController = (function() {

        /**
         * @constructor
         * @param {Object} $scope - Angular scope object
         * @param {Object} $window - Angular window object
         * @param {Object} $timeout - Angular timeout object
         * @param {Object} $http - Angular http object
         */
        function regionRevampedConstructor($scope, $window, $timeout, $http, regionStorageFactory) {
            this.$scope = $scope;
            this.$window = $window;
            this.$timeout = $timeout;
            this.$http = $http;
            this.regionStorageFactory = regionStorageFactory;
            this.tab = this.initTab || 'communities';
            this.cache = {};
            this.requestCache = {};

            /**
             * @property {Object[]} _tabData - Array of tab data objects
             * @property {string} tab - Tab name
             * @property {string} name - Tab display name
             * @property {string} endpoint - Endpoint to retrieve data for tab
             * @property {boolean} isLoaded - Whether tab data has been retrieved
             * @property {Object[]} data - Tab data
             */
            this._tabData = [{
                tab: 'communities',
                name: 'Communities',
                label: 'Communities',
                endpoint: '/api/region-revamped/communities',
                seoEndpoint: '/api/region-revamped/seo/communities',
                cardEndpoint: '/api/region-revamped/community',
                sortByOptions: [
                    { key: 'default', name: 'Sort' },
                    { key: 'price--ascending', name: 'Price Low to High' },
                    { key: 'price--descending', name: 'Price High to Low' },
                    { key: 'name--ascending', name: 'Name A-Z' },
                    { key: 'name--descending', name: 'Name Z-A' },
                    { key: 'city--ascending', name: 'City A-Z' },
                    { key: 'city--descending', name: 'City Z-A' }
                ]
            }, {
                tab: 'our-floorplans',
                name: 'Floorplans',
                label: 'Floorplans',
                endpoint: '/api/region-revamped/plans',
                seoEndpoint: '/api/region-revamped/seo/plans',
                cardEndpoint: '/api/region-revamped/plan',
                sortByOptions: [
                    { key: 'default', name: 'Sort' },
                    { key: 'price--ascending', name: 'Price Low to High' },
                    { key: 'price--descending', name: 'Price High to Low' },
                    { key: 'name--ascending', name: 'Name A-Z' },
                    { key: 'name--descending', name: 'Name Z-A' }
                ]
            }, {
                tab: 'move-in-ready',
                name: 'Move-In Ready',
                label: 'Move-In Ready Homes',
                endpoint: '/api/region-revamped/homes',
                seoEndpoint: '/api/region-revamped/seo/move-in-ready',
                cardEndpoint: '/api/region-revamped/home',
                sortByOptions: [
                    { key: 'default', name: 'Sort' },
                    { key: 'price--ascending', name: 'Price Low to High' },
                    { key: 'price--descending', name: 'Price High to Low' },
                    { key: 'name--ascending', name: 'Name A-Z' },
                    { key: 'name--descending', name: 'Name Z-A' },
                    { key: 'city--ascending', name: 'City A-Z' },
                    { key: 'city--descending', name: 'City Z-A' },
                    { key: 'availability--ascending', name: 'Availability: Soonest' },
                    { key: 'availability--descending', name: 'Availability: Latest' }
                ]
            }, {
                tab: 'model-homes',
                name: 'Model Homes',
                label: 'Model Homes',
                endpoint: '/api/region-revamped/model-homes',
                seoEndpoint: '/api/region-revamped/seo/model-homes',
                cardEndpoint: '/api/region-revamped/model-home',
                sortByOptions: [
                    { key: 'default', name: 'Sort' },
                    { key: 'name--ascending', name: 'Name A-Z' },
                    { key: 'name--descending', name: 'Name Z-A' }
                ]
            }];

            this._tabData.forEach(function(tabData) {
                var tabDataDefaults = {
                    isEmpty: false,
                    isLoaded: false,
                    isMapDataLoaded: false,
                    isModelDataLoaded: false,
                    isSeoDataLoaded: false,
                    isMapPannedAway: false,
                    isFiltered: false,
                    data: [[]],
                    mapData: [],
                    pages: [],
                    currentPage: 1,
                    lastPage: 1,
                    totalCount: 0,
                    seo: {
                        blurb: '',
                        title: '',
                        description: '',
                        seoone_title: '',
                        seotwo_title: '',
                        seothree_title: '',
                        seoone_copy: '',
                        seotwo_copy: '',
                        seothree_copy: '',
                        seoonehero: null,
                        seotwohero: null,
                        seothreehero: null
                    }
                };

                Object.assign(tabData, tabDataDefaults);
            });

            // Reasonable defaults in case properties bound to controller are not set
            if (this.region) {
                this.regionStorageFactory.setRegion(this.region);
            }
            this.region = this.region || {
                id: 0,
                latitude: 40.4173,
                longitude: -82.9071,
                name: 'Region',
                state: 'State',
                seo_name: 'region',
                communities: []
            };
            this.isLoading = false;
            this.tabData = this._tabData[0];
            // this.title = this.title || 'Find Communities';
            this.title = this.title || 'New Home Communities';
            this.didUserToggleDisplay = false;
            this.display = this.$window.innerWidth < 1024 ? 'grid' : 'map';
            this.mappedCommunities = [];
            this.communities = [];
            this.mappedPlans = [];
            this.plans = [];
            this.mappedHomes = [];
            this.homes = [];
            this.mappedModelHomes = [];
            this.modelHomes = [];
            this.hiddenMarkerIds = [];
            this.resultsCount = this.resultsCount || 0;
            this.activeCommunity = null;
            this.activePlan = null;
            this.activeHome = null;
            this.activeModelHome = null;
            this.isMapLoaded = false;
            this.isMapDataLoaded = false;
            this.ignoreMapRefresh = false;
            this.map = null;
            this.activeFilterOptions = '';
            this.selectedSubregions = [];
            this.resizeDebounce = null;
            this.scrollToTopDebounce = null;
            this.boundaryChangeDebounce = null;
            this.appliedFilterCity = 0;
            this.appliedFilterPrice = 0;
            this.appliedFilterBeds = 0;
            this.appliedFilterBaths = 0;
            this.appliedFilterType = 0;
            this.appliedFilterSchool = 0;
            this.appliedFilterTotal = 0;
            this.slickSettings = {
                arrows: false,
                infinite: false,
                dots: true,
                cssEase: 'ease-out',
                method: {}
            };
            this.modalData = {
                phone: '',
                hasModels: false,
                model: null,
            };

            // Scoped variables
            this.$scope.mapData = {
                markers: [],
            };
            this.previousWindowWidth = this.$window.innerWidth;
            this.sortBy = 'default';
            this.$scope.filters = {
                subregions: this.subregionOptions.reduce(function(accumulator, currentValue) {
                    accumulator[currentValue.id] = false;
                    return accumulator;
                }, {}),
                price: '',
                beds: '',
                baths: '',
                type: '',
                school: '',
            };
            this.$scope.modalFilters = Object.assign({}, this.$scope.filters);

            // Bindings
            this.changeTab = this.changeTab.bind(this);
            this.setTitle = this.setTitle.bind(this);
            this.setTab = this.setTab.bind(this);
            this.getTab = this.getTab.bind(this);
            this.getUrlParts = this.getUrlParts.bind(this);
            this.getFilterParams = this.getFilterParams.bind(this);
            this.setCache = this.setCache.bind(this);
            this.fetchData = this.fetchData.bind(this);
            this.loadData = this.loadData.bind(this);
            this.loadMapData = this.loadMapData.bind(this);
            this.loadCardData = this.loadCardData.bind(this);
            this.setTabData = this.setTabData.bind(this);
            this.setMapData = this.setMapData.bind(this);
            this.onDataLoaded = this.onDataLoaded.bind(this);
            this.onMapDataLoaded = this.onMapDataLoaded.bind(this);
            this.onSeoDataLoaded = this.onSeoDataLoaded.bind(this);
            this.onMapLoaded = this.onMapLoaded.bind(this);
            this.onMapBoundsChanged = this.onMapBoundsChanged.bind(this);
            this.generateMarker = this.generateMarker.bind(this);
            this.fitMapToMarkers = this.fitMapToMarkers.bind(this);
            this.onFilterTriggerClick = this.onFilterTriggerClick.bind(this);
            this.onFiltersModalOpen = this.onFiltersModalOpen.bind(this);
            this.onFiltersModalClose = this.onFiltersModalClose.bind(this);
            this.onFiltersModalApply = this.onFiltersModalApply.bind(this);
            this.onFiltersModalReset = this.onFiltersModalReset.bind(this);
            this.onCardMouseover = this.onCardMouseover.bind(this);
            this.onCardMouseout = this.onCardMouseout.bind(this);
            this.clearFilters = this.clearFilters.bind(this);
            this.recenterMap = this.recenterMap.bind(this);
            this.setActiveCard = this.setActiveCard.bind(this);
            this.onMarkerClick = this.onMarkerClick.bind(this);
            this.onRootElementClick = this.onRootElementClick.bind(this);
            this.onDocumentKeyDown = this.onDocumentKeyDown.bind(this);
            this.onFiltersChange = this.onFiltersChange.bind(this);
            this.onSortsChange = this.onSortsChange.bind(this);
            this.slickNext = this.slickNext.bind(this);
            this.slickPrev = this.slickPrev.bind(this);
            this.onResize = this.onResize.bind(this);
            this.onToggleDisplayClick = this.onToggleDisplayClick.bind(this);
            this.refreshComponents = this.refreshComponents.bind(this);
            this.onScheduleTourClick = this.onScheduleTourClick.bind(this);
            this.onScheduleTourCloseClick = this.onScheduleTourCloseClick.bind(this);
            this.initHubspotForm = this.initHubspotForm.bind(this);
            this.scrollToTop = this.scrollToTop.bind(this);
            this.onNextClick = this.onNextClick.bind(this);
            this.onPrevClick = this.onPrevClick.bind(this);
            this.onPageClick = this.onPageClick.bind(this);
            this.clearMarkers = this.clearMarkers.bind(this);

            Promise.all([
                google.maps.importLibrary('marker'),
                google.maps.importLibrary('maps')
            ]).then(this.onMapLoaded);

            // Iniitialize
            this.init();
        }

        /**
         * Initialize controller and retrieve data
         * @method init
         */
        regionRevampedConstructor.prototype.init = function() {
            // Set tab
            this.setTab(this.tab);
            this.setTitle(this.tab);
            this.$scope.$watch('filters', this.onFiltersChange, true);
            this.$scope.$watch('vm.sortBy', this.onSortsChange);
        };

        /**
         * Get the cache key for the given url and params
         * @param {string} url
         * @param {URLSearchParams} params
         * @returns
         */
        regionRevampedConstructor.prototype.getCacheKey = function(url, params) {
            return params ? btoa(url + '?' + params.toString()) : btoa(url);
        }

        /**
         * Change tab. Handles click event and sets tab
         * @see setTab
         * @param {Event} $event - Click event
         * @param {string} tab - Tab to change to (communities, our-floorplans, move-in-ready, model-homes)
         * @method changeTab
         */
        regionRevampedConstructor.prototype.changeTab = function($event, tab) {
            $event.preventDefault();
            this.setTab(tab);
            this.setTitle(tab);
            this.clearFilters();
        };

        /**
         * Set title
         * @param {string} tab - Title to set depends on selected tab
         * @method setTitle
         */
        regionRevampedConstructor.prototype.setTitle = function(tab) {
            switch (tab) {
                // case 'communities': this.title = 'Find Communities'; break;
                case 'communities': this.title = 'New Home Communities'; break;
                case 'our-floorplans': this.title = 'New Home Floorplans'; break;
                case 'move-in-ready': this.title = 'New Move-In Ready Homes'; break;
                case 'model-homes': this.title = 'Model Homes'; break;
            }
        };

        /**
         * Set results count
         * @param {string} tab - Results count to set depends on selected tab
         * @method setResultsCount
         */
        regionRevampedConstructor.prototype.setResultsCount = function(tab) {
            var isSingular = this.tabData.totalCount === 1;
            var titles = {
                'communities': { 'singular': ' Community', 'plural': ' Communities' },
                'our-floorplans': { 'singular': ' Floorplan', 'plural': ' Floorplans' },
                'move-in-ready': { 'singular': ' Move-In Ready Home', 'plural': ' Move-In Ready Homes' },
                'model-homes': { 'singular': ' Model Home', 'plural': ' Model Homes' }
            };

            this.resultsCount = this.tabData.totalCount +
                (isSingular ? titles[tab].singular : titles[tab].plural);
        };

        /**
         * Set tab
         * @param {string} tab - Tab to set (communities, floorplans, move-in-ready, model-homes)
         * @method setTab
         */
        regionRevampedConstructor.prototype.setTab = function(tab) {
            this.isLoading = true;
            this.$timeout((function() {
                this.hiddenMarkerIds = [];
                this._tabData.forEach(function(tabData) {
                    tabData.currentPage = 1;
                });

                this.tab = tab || 'communities';

                this.onFlyoutCloseClick();

                // Update url
                this.$window.history.replaceState({}, '', '/find-new-homes/' + this.region.seo_name + '/' + this.tab);

                this.loadData();
            }).bind(this));
        };

        /**
         * Get the current tab data
         * @returns {Object} - Tab data
         */
        regionRevampedConstructor.prototype.getTab = function() {
            var loadDataForTab = this._tabData.find(function(tabData) {
                return tabData.tab === this.tab;
            }, this);

            return loadDataForTab;
        };

        /**
         * Get URL parts for endpoints
         * @returns {string} - URL parts
         */
        regionRevampedConstructor.prototype.getUrlParts = function() {
            // Add region and subregion ids to endpoint params
            var params = '/' + this.regionId;
            if (this.subregionId) {
                params += '/' + this.subregionId;
            }

            return params;
        };

        /**
         * Set cache
         * @param {string} cacheKey - Cache key
         * @param {object} data - Data to cache
         */
        regionRevampedConstructor.prototype.setCache = function(cacheKey, data) {
            this.cache[cacheKey] = data;
        };

        /**
         * Centralized method for fetching data from the server and caching it
         * @param {string} url - API endpoint URL
         * @param {URLSearchParams} params - Query parameters
         * @returns {Promise} - Promise that resolves with the fetched data
         */
        regionRevampedConstructor.prototype.fetchData = function(url, params) {
            var cacheKey = this.getCacheKey(url, params);
            var endpoint = params ? url + '?' + params.toString() : url;
            var response = null;

            if (this.requestCache[cacheKey]) {
                // Request is already in progress, return the promise
                return this.requestCache[cacheKey];
            }

            // Set the request cache

            if (this.cache[cacheKey]) {
                // Return the cached dta
                return Promise.resolve(this.cache[cacheKey]);
            }

            // Fetch data and store in cache
            response = this.$http.get(endpoint).then((function (response) {
                this.setCache(cacheKey, response.data);
                delete this.requestCache[cacheKey];
                return response.data;
            }).bind(this));

            this.requestCache[cacheKey] = response;

            return response;
        };

        /**
         * Retrieve data for tab
         * @method loadData
         */
         regionRevampedConstructor.prototype.loadData = function() {
            var loadDataForTab = this.getTab();
            var params = this.getUrlParts();

            // Retrieve data for tab
            this.loadMapData();
            this.loadCardData();


            // Retrieve SEO data for tab
            this.fetchData(loadDataForTab.seoEndpoint + params)
                .then(this.onSeoDataLoaded)
                .catch(function(error) {
                    console.error('RegionRevamped seo response errored:', error);
                });
        };

        /**
         * Get filter parameters
         * @returns {URLSearchParams} - Filter parameters
         * @method getFilterParams
         */
        regionRevampedConstructor.prototype.getFilterParams = function() {
            var filters = new URLSearchParams();

            Object.entries(this.$scope.filters).forEach(function([key, value]) {
                // Skip subregions
                if (key === 'subregions') {
                    return;
                }

                // Skip empty values
                if (value) {
                    filters.append(key, value);
                }
            });

            if (this.selectedSubregions.length) {
                filters.append('subregions', this.selectedSubregions.join(','));
            }

            return filters;
        };

        /**
         * Load card data for tab
         * @param {number} [page=1] - Page number to load
         * @method loadCardData
         */
        regionRevampedConstructor.prototype.loadCardData = function(page) {
            var loadDataForTab = this.getTab();
            var urlParts = this.getUrlParts();
            var filters = this.getFilterParams();


            if (this.hiddenMarkerIds.length) {
                filters.append('hiddenMarkers', this.hiddenMarkerIds.join(','));
            }

            if (this.sortBy !== 'default') {
                filters.append('sortType', this.sortBy.split('--')[0]);
                filters.append('sortOrder', this.sortBy.split('--')[1] === 'ascending' ? 'asc' : 'desc');
            }

            filters.append('page', page || 1);

            this.fetchData(loadDataForTab.endpoint + urlParts, filters)
                .then(this.onDataLoaded)
                .catch(function(error) {
                    console.error('RegionRevamped init response errored:', error);
                });
        };

        /**
         * Retrieve map data for tab
         * @method loadMapData
         */
        regionRevampedConstructor.prototype.loadMapData = function() {
            var loadDataForTab = this.getTab();
            if (loadDataForTab.tab === 'our-floorplans') {
                return;
            }

            var urlParts = this.getUrlParts();
            var filters = this.getFilterParams();

            this.fetchData(loadDataForTab.endpoint + '/markers' + urlParts, filters)
                .then(this.onMapDataLoaded)
                .catch(function(error) {
                    console.error('RegionRevamped init response errored:', error);
                });
        };

        /**
         * Callback for init response using tabData.endpoint
         * @param {Object} data - object
         * @method onDataLoaded
         */
        regionRevampedConstructor.prototype.onDataLoaded = function(data) {
            this._tabData.forEach((function(tabData) {
                if (data[tabData.tab]) {
                    // remove the first and last page from the array of pages
                    var pages = Array.from({ length: data[tabData.tab].last_page }, function(_, i) { return i + 1; });
                    pages.shift();
                    pages.pop();
                    tabData.isModelDataLoaded = true;
                    tabData.currentPage = data[tabData.tab].current_page;
                    tabData.lastPage = data[tabData.tab].last_page;
                    tabData.pages = pages;
                    tabData.totalCount = data[tabData.tab].total;
                    tabData.data[tabData.currentPage - 1] = data[tabData.tab].data;
                    tabData.isFiltered = data[tabData.tab].isFiltered;
                    tabData.isLoaded = tabData.isModelDataLoaded;
                    tabData.isMapPannedAway = data[tabData.tab].isMapPannedAway;
                    tabData.isEmpty = !tabData.isFiltered && tabData.totalCount === 0 && !tabData.isMapPannedAway;
                }
            }).bind(this));

            this.setTabData();
        };

        /**
         * Callback for map data response using tabData.endpoint + '/markers'
         * @param {Object} data - Response object
         * @method onMapDataLoaded
         */
        regionRevampedConstructor.prototype.onMapDataLoaded = function(data) {
            this.clearMarkers();
            this._tabData.forEach((function(tabData) {
                if (data[tabData.tab]) {
                    tabData.mapData = data[tabData.tab];
                    tabData.isMapDataLoaded = true;
                }
            }).bind(this));

            this.isMapDataLoaded = true;

            if (this.isMapLoaded) {
                this.setMapData();
            }
        };

        /**
         * Callback for SEO response using tabData.seoEndpoint
         * @param {Object} data
         * @param {string} data.blurb
         * @param {string} data.title
         * @param {string} data.description
         * @param {string} data.seoone_title
         * @param {string} data.seotwo_title
         * @param {string} data.seothree_title
         * @param {string} data.seoone_copy
         * @param {string} data.seotwo_copy
         * @param {string} data.seothree_copy
         * @param {Object} data.seoonehero
         * @param {Object} data.seotwohero
         * @method onSeoDataLoaded
         */
        regionRevampedConstructor.prototype.onSeoDataLoaded = function(data) {
            this._tabData.forEach((function(tabData) {
                if (data[tabData.tab]) {
                    tabData.seo = data[tabData.tab];
                    tabData.isSeoDataLoaded = true;
                }
            }).bind(this));
        };

        /**
         * Set tab data
         * @method setTabData
         */
        regionRevampedConstructor.prototype.setTabData = function() {
            this.tabData = this._tabData.find(function(tabData) {
                return tabData.tab === this.tab;
            }, this);

            if (this.tab === 'communities') {
                this.communities = this.tabData.data[this.tabData.currentPage - 1];
                this.resizeDebounce = this.$timeout(this.refreshComponents, 200);
            } else if (this.tab === 'our-floorplans') {
                this.plans = this.tabData.data[this.tabData.currentPage - 1];
                this.resizeDebounce = this.$timeout(this.refreshComponents, 200);
            } else if (this.tab === 'move-in-ready') {
                this.homes = this.tabData.data[this.tabData.currentPage - 1];
                this.resizeDebounce = this.$timeout(this.refreshComponents, 200);
            } else if (this.tab === 'model-homes') {
                this.modelHomes = this.tabData.data[this.tabData.currentPage - 1];
                this.resizeDebounce = this.$timeout(this.refreshComponents, 200);
            }
            this.setResultsCount(this.tab);
            this.scrollToTopDebounce = this.$timeout(this.scrollToTop, 200);
        };

        /**
         * Sets the map data for the current tab
         * @method setMapData
         */
        regionRevampedConstructor.prototype.setMapData = function() {
            this.tabData = this._tabData.find(function(tabData) {
                return tabData.tab === this.tab;
            }, this);

            if (this.tabData.tab !== 'our-floorplans') {
                this.$scope.mapData.markers = this.tabData.mapData.map(this.generateMarker).filter(Boolean);
                this.$timeout(this.fitMapToMarkers, 300);
            }
        };

        /**
         * Clears all markers from the map
         * @method clearMarkers
         */
        regionRevampedConstructor.prototype.clearMarkers = function() {
            var markerLen = this.$scope.mapData.markers.length;

            for (var i = 0; i < markerLen; i++) {
                this.$scope.mapData.markers[i].content.removeEventListener('mouseenter', this.handleMarkerOver);
                this.$scope.mapData.markers[i].content.removeEventListener('mouseleave', this.handleMarkerOut);
                this.$scope.mapData.markers[i].setMap(null);
            }

            this.$scope.mapData.markers = [];
        };

        /**
         * Callback for Google maps loaded

         * @param {google.maps} maps - Google maps object
         */
        regionRevampedConstructor.prototype.onMapLoaded = function(maps) {
            var regionLat = this.region.latitude && !isNaN(this.region.latitude)
                ? parseFloat(this.region.latitude)
                : 40.4173;
            var regionLng = this.region.longitude && !isNaN(this.region.longitude)
                ? parseFloat(this.region.longitude)
                : -82.9071;

            this.isMapLoaded = true;

            this.map = new google.maps.Map(document.getElementById('map'), {
                center: {
                    lat: regionLat,
                    lng: regionLng
                },
                zoom: 10,
                minZoom: 5,
                maxZoom: 17,
                mapId: 'map',
                mapTypeControl: false,
                streetViewControl: false,
                fullscreenControl: true,
                scrollwheel: false,
                panControl: true,
                zoomControl: true,
                zoomControlOptions: {
                    position: google.maps.ControlPosition.TOP_RIGHT
                }
            });

            if (this.isMapDataLoaded) {
                this.setMapData();
            }

            this.map.addListener('zoom_changed', this.onMapBoundsChanged);
            this.map.addListener('center_changed', this.onMapBoundsChanged);
            this.map.addListener('bounds_changed', this.onMapBoundsChanged);
        };

        /**
         * Handles bounds changed event on map object (when the map is panned or zoomed)
         * @method onMapBoundsChanged
         */
        regionRevampedConstructor.prototype.onMapBoundsChanged = function() {
            var mapBounds = this.map.getBounds();

            if (this.boundaryChangeDebounce) {
                this.$timeout.cancel(this.boundaryChangeDebounce);
            }

            if (this.display !== 'map') {
                this.hiddenMarkerIds = [];
                return;
            }

            this.boundaryChangeDebounce = this.$timeout((function() {
                var hiddenMarkerIds = this.$scope.mapData.markers.filter(function(marker) {
                    if (!marker.position.lat || !marker.position.lng) {
                        // We'll just pretend it's in view if it doesn't have a lat/lng
                        return false;
                    }
                    var markerPosition = new google.maps.LatLng(marker.position.lat, marker.position.lng);
                    var isMarkerInView = mapBounds.contains(markerPosition);
                    return !isMarkerInView;
                }).map(function (marker) {
                    return marker.content.id.replace('marker-', '');
                });

                // Compare with previous visible markers
                if (JSON.stringify(hiddenMarkerIds) !== JSON.stringify(this.hiddenMarkerIds)) {
                    this.ignoreMapRefresh = true;
                    this.hiddenMarkerIds = hiddenMarkerIds;
                    this.loadCardData();
                }
            }).bind(this), 500);
        };

        /**
         * Generate marker object for community
         * @param {Object} item - Item object
         * @returns {Object} - Marker object
         * @method generateMarker
         */
        regionRevampedConstructor.prototype.generateMarker = function(item) {
            // If google maps is not loaded, return early
            if (!google || !google.maps) {
                return;
            }

            // Return early if there's no latitude or longitude for the item
            if (!item.latitude || !item.longitude) {
                return;
            }

            var label = '';
            if (this.tabData.tab === 'communities') {
                label = item.name;
            } else if (this.tabData.tab === 'our-floorplans') {
                // Do nothing - floorplans don't have a map
            } else if (this.tabData.tab === 'move-in-ready') {
                label = item.formattedPrice;
            } else if (this.tabData.tab === 'model-homes') {
                label = item.name;
            }

            var lat = typeof item.latitude === 'string' ? parseFloat(item.latitude) : item.latitude;
            var lng = typeof item.longitude === 'string' ? parseFloat(item.longitude) : item.longitude;

            if (lat === 0 && lng === 0) {
                return;
            }

            var pinElem = document.createElement('div');
            var isModelHome = this.tabData.tab === 'model-homes' || (typeof item.has_models !== 'undefined' && item.has_models);

            pinElem.setAttribute('id', `marker-${item.id}`);
            pinElem.className = 'reg__marker ' + (isModelHome ? 'reg__marker--model-home' : '');
            pinElem.innerHTML = '<div class="reg__marker-label">' + label + '</div>';
            pinElem.innerHTML += '<img width="37" height="59" src="' + (
                isModelHome ? '/images/site/global/pin-model-home-hover.svg' : '/images/site/global/pin-hover.svg'
            ) + '" alt="location marker">';


            var marker = new google.maps.marker.AdvancedMarkerElement({
                map: this.map,
                position: { lat: lat, lng: lng },
                content: pinElem,
                title: label,
            });

            var markerData = {
                view: marker,
                model: item,
            };

            // Add marker listeners
            marker.addListener('click', this.handleMarkerClick.bind(this, markerData));
            marker.content.addEventListener('mouseenter', this.handleMarkerOver.bind(this, markerData));
            marker.content.addEventListener('mouseleave', this.handleMarkerOut.bind(this, markerData));

            return marker;
        };

        /**
         * Fit map to markers
         * @method fitMapToMarkers
         */
        regionRevampedConstructor.prototype.fitMapToMarkers = function() {
            // If there are no markers, don't do anything
            if (!this.$scope.mapData.markers.length || !google.maps) {
                return;
            }

            var lats = this.$scope.mapData.markers.map(function(marker) {
                return marker.position.lat;
            }).sort(function(a, b) {
                return a - b;
            });


            var lngs = this.$scope.mapData.markers.map(function(marker) {
                return marker.position.lng;
            }).sort(function(a, b) {
                return a - b;
            });

            var latMin = lats[0];
            var latMax = lats[lats.length - 1];
            var lngMin = lngs[0];
            var lngMax = lngs[lngs.length - 1];

            // Add 0.1 degrees to each side of the bounds
            latMin -= 0.1;
            latMax += 0.1;
            lngMin -= 0.1;

            lngMax += 0.1;


            var bounds = new google.maps.LatLngBounds(
                new google.maps.LatLng(latMin, lngMin),
                new google.maps.LatLng(latMax, lngMax)
            );

            this.map.fitBounds(bounds);
        };

        /**
         * Handles a filter trigger click event
         * @param {Event} $event - Click event
         * @param {string} filter - Filter to set
         */
        regionRevampedConstructor.prototype.onFilterTriggerClick = function($event, filter) {
            $event.preventDefault();
            $event.stopPropagation();

            this.activeFilterOptions = filter === this.activeFilterOptions ? '' : filter;
        };

        /**
         * Handles a click event on the filters modal open button
         * @method onFiltersModalOpen
         */
        regionRevampedConstructor.prototype.onFiltersModalOpen = function() {
            if (this.filtersModalElem) {
                this.filtersModalElem.showModal();
                document.querySelector('#region-revamp').style.overflow = 'hidden';
            }
        };

        /**
         * Handles a click event on the filters modal close button
         * @method onFiltersModalClose
         */
        regionRevampedConstructor.prototype.onFiltersModalClose = function() {
            if (this.filtersModalElem.returnValue === 'apply') {
                this.$scope.filters.subregions = this.subregionOptions.reduce((function(accumulator, currentValue) {
                    accumulator[currentValue.id] = this.$scope.modalFilters.subregions.includes(currentValue.id + '');
                    return accumulator;
                }).bind(this), {}),
                this.$scope.filters.price = this.$scope.modalFilters.price;
                this.$scope.filters.beds = this.$scope.modalFilters.beds;
                this.$scope.filters.baths = this.$scope.modalFilters.baths;
                this.$scope.filters.type = this.$scope.modalFilters.type;
                this.$scope.filters.school = this.$scope.modalFilters.school;
                this.filtersModalElem.returnValue = '';
            }
        };

        /**
         * Handles a click event on the filters modal apply button (submit)
         * @param {Event} $event - Click event
         * @method onFiltersModalApply
         */
        regionRevampedConstructor.prototype.onFiltersModalApply = function($event) {
            if (this.filtersModalElem) {
                this.filtersModalElem.close('apply');
                document.querySelector('#region-revamp').style.overflow = '';
            }
        };

        /**
         * Handles a click event on the filters modal reset button
         * @method onFiltersModalReset
         */
        regionRevampedConstructor.prototype.onFiltersModalReset = function() {
            this.$timeout((function() {
                this.$scope.modalFilters.subregions = [];
                this.$scope.modalFilters.price = '';
                this.$scope.modalFilters.beds = '';
                this.$scope.modalFilters.baths = '';
                this.$scope.modalFilters.type = '';
                this.$scope.modalFilters.school = '';
            }).bind(this));
        };

        /**
         * Clears all filters
         * @method clearFilters
         */
        regionRevampedConstructor.prototype.clearFilters = function() {
            this.$timeout((function() {
                this.$scope.filters.subregions = this.subregionOptions.reduce((function(accumulator, currentValue) {
                    accumulator[currentValue.id] = false;
                    return accumulator;
                }).bind(this), {});
                this.$scope.filters.price = '';
                this.$scope.filters.beds = '';
                this.$scope.filters.baths = '';
                this.$scope.filters.type = '';
                this.$scope.filters.school = '';
            }).bind(this));
        };

        /**
         * Recenter map
         * @method recenterMap
         */
        regionRevampedConstructor.prototype.recenterMap = function() {
            this.$timeout((function() {
                this.fitMapToMarkers();

                this.setResultsCount(this.tabData.tab);

            }).bind(this));
        };

        /**
         * Helps us remove focus from the filter options when clicking on the page
         * @param {Event} event - Click event
         * @method onRootElementClick
         */
        regionRevampedConstructor.prototype.onRootElementClick = function(event) {
            var target = event.target;
            var filterOptionsElem = target.closest('.reg__filter-options');

            // Close the filter options if they are open and the click is not on the filter options
            if (!filterOptionsElem) {
                this.$timeout((function() {
                    this.activeFilterOptions = '';
                }).bind(this));
            }
        };

        /**
         * Handles a keydown event on the document
         * @param {Event} event - Keydown event
         * @method onDocumentKeyDown
         */
        regionRevampedConstructor.prototype.onDocumentKeyDown = function(event) {
            // Capture the escape key
            if (event.key === 'Escape') {
                // Close the filter options if they are open
                if (this.activeFilterOptions) {
                    this.$timeout((function() {
                        this.activeFilterOptions = '';
                    }).bind(this));
                }
            }
        };

        /**
         * Handles a change event on the filters object and sortBy property
         * @method onFiltersChange
         */
        regionRevampedConstructor.prototype.onFiltersChange = function() {
            // Update the selected subregions for use in the filters
            this.selectedSubregions = Object.keys(this.$scope.filters.subregions).filter(function(subregionId) {
                return this.$scope.filters.subregions[subregionId];
            }, this).map(function(subregionId) {
                return parseInt(subregionId, 10);
            });

            // Update modal filters
            this.$scope.modalFilters.subregions = this.selectedSubregions.map(function(subregionId) {
                return subregionId + '';
            });
            this.$scope.modalFilters.price = this.$scope.filters.price;
            this.$scope.modalFilters.beds = this.$scope.filters.beds;
            this.$scope.modalFilters.baths = this.$scope.filters.baths;
            this.$scope.modalFilters.type = this.$scope.filters.type;
            this.$scope.modalFilters.school = this.$scope.filters.school;

            // Update the applied filter counts
            this.appliedFilterCity = this.selectedSubregions.length;
            this.appliedFilterPrice = this.$scope.filters.price ? 1 : 0;
            this.appliedFilterBeds = this.$scope.filters.beds ? 1 : 0;
            this.appliedFilterBaths = this.$scope.filters.baths ? 1 : 0;
            this.appliedFilterType = this.$scope.filters.type ? 1 : 0;
            this.appliedFilterSchool = this.$scope.filters.school ? 1 : 0;
            this.appliedFilterTotal = this.appliedFilterCity + this.appliedFilterPrice + this.appliedFilterBeds + this.appliedFilterBaths + this.appliedFilterType + this.appliedFilterSchool;
            this.loadMapData();
            this.loadCardData();
        };

        /**
         * Handles a change event on the sortBy property
         * @method onSortsChange
         */
        regionRevampedConstructor.prototype.onSortsChange = function() {
            var sortByOptions = this.tabData.sortByOptions;

            if (!this.sortBy) {
                this.sortBy = sortByOptions[0].key;
                return;
            }

            this.loadCardData();
        };

        /**
         * Handles a click event on the next button for a slick slider
         * @param {Event} $event - Click event
         * @method slickNext
         */
        regionRevampedConstructor.prototype.slickNext = function($event) {
            var nearestContainer = $event.target.closest('.reg__card-image');
            var nearestSlick = nearestContainer.querySelector('.slick-slider');

            if (nearestSlick && nearestSlick.slick) {
                nearestSlick.slick.slickNext();
            }
        };

        /**
         * Handles a click event on the previous button for a slick slider
         * @param {Event} $event - Click event
         * @method slickPrev
         */
        regionRevampedConstructor.prototype.slickPrev = function($event) {
            var nearestContainer = $event.target.closest('.reg__card-image');
            var nearestSlick = nearestContainer.querySelector('.slick-slider');

            if (nearestSlick && nearestSlick.slick) {
                nearestSlick.slick.slickPrev();
            }
        };

        /**
         * Handles a click event on the toggle display button
         * @param {string} display - Display to toggle to (grid, map)
         * @method onToggleDisplayClick
         */
        regionRevampedConstructor.prototype.onToggleDisplayClick = function(display) {
            this.didUserToggleDisplay = true;
            this.display = display;
            // Scroll back to top to prevent tab content
            // from disappearing on toggle to map
            if(this.display === 'map'){
                var mainSection = document.querySelector('.reg__listings');
                if(mainSection){
                    mainSection.scrollIntoView();
                }
            }
            this.resizeDebounce = this.$timeout(this.refreshComponents, 200);
        };

        /**
         * Handles a resize event on the window
         * @method onResize
         */
        regionRevampedConstructor.prototype.onResize = function() {
            // If the user hasn't manually toggled the display,
            // change it to grid by default if the window is small
            if (!this.didUserToggleDisplay) {
                var windowWidth = this.$window.innerWidth;
                var wasPreviouslyMobile = this.previousWindowWidth < 1024;
                var isMobileNow = windowWidth < 1024;

                // If the window is small and the display is map, change it to grid
                if (isMobileNow && !wasPreviouslyMobile && this.display === 'map') {
                    this.display = 'grid';
                    this.onFlyoutCloseClick();
                } else if (!isMobileNow && wasPreviouslyMobile && this.display === 'grid') {
                    this.display = 'map';
                }

                this.previousWindowWidth = windowWidth;
            }
            this.resizeDebounce = this.$timeout(this.refreshComponents, 200);
        };

        /**
         * Re-initializes slick sliders on card components and repositions the map
         * @method refreshComponents
         */
        regionRevampedConstructor.prototype.refreshComponents = function() {
            this.isLoading = false;

            // Cancel any existing resize debounce
            if (this.resizeDebounce) {
                this.$timeout.cancel(this.resizeDebounce);
            }

            // Get all slick sliders
            var slickSliders = document.querySelectorAll('.slick-slider');

            // // Re-init slick sliders
            for (var i = 0; i < slickSliders.length; i++) {
                var slickSlider = slickSliders[i];
                if (slickSlider.slick) {
                    slickSlider.slick.refresh();
                }
            }

            // Reposition the map if it exists
            if (this.map && !this.ignoreMapRefresh) {
                this.fitMapToMarkers();
            }

            this.ignoreMapRefresh = false;
        };

        /**
         * Scrolls to the top of the page
         * @method scrollToTop
         */
        regionRevampedConstructor.prototype.scrollToTop = function() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        };

        /**
         * Handles a click event on the schedule tour button on a community card
         * @param {Object} community - Community object
         * @method onScheduleTourClick
         */
        regionRevampedConstructor.prototype.onScheduleTourClick = function(community) {
            this.modalData.phone = community
                ? (community.sales_office_phone || community.sales_agent_1_phone || community.sales_agent_2_phone || this.region.sales_phone)
                : this.region.sales_phone;
            this.modalData.hasModels = community ? community.has_models : false;

            this.initHubspotForm(community);

            if (this.scheduleTourModalElem) {
                this.flyoutModalElem.close();
                this.scheduleTourModalElem.showModal();
            }
        };

        /**
         * Initializes hubspot form
         * @param {Object} community
         * @method initHubspotForm
         */
        regionRevampedConstructor.prototype.initHubspotForm = function(community) {
            var formContainers = this.scheduleTourModalElem.querySelectorAll('.hubspot-form-container');

            if (!formContainers.length) {
                console.error('No hubspot form container found');
                return;
            }

            Array.prototype.forEach.call(formContainers, function(container) {
                var hsFormId = container.getAttribute('form-id');

                if (!hsFormId) {
                    console.error('Form ID not specified for a hubspot form container', container);
                    return;
                }

                if (typeof hbspt !== 'undefined') {
                    hbspt.forms.create({
                        region: 'na1',
                        portalId: '8273304',
                        formId: hsFormId,
                        target: '#' + container.id,
                        onFormReady: function(form) {
                            var communityName = community ? community.name : 'No Community';
                            var formElem = form.get(0);
                            var fieldSelector = '#community_of_interest_1-' + hsFormId;
                            var communityField = formElem.querySelector(fieldSelector);
                            var communityFieldContainer = communityField ? communityField.parentElement.parentElement : null;

                            if (communityField) {
                                communityField.value = communityName;
                                communityFieldContainer.style.display = 'none';
                            }

                            var labels = formElem.getElementsByTagName('label');
                            Array.from(labels).forEach(function(label) {
                                var labelText = label.textContent;
                                var inputId = label.id.replace('label-', '');
                                var inputSelector = inputId ? '#' + inputId : '';
                                var inputElem = inputSelector ? formElem.querySelector('#' + inputId) : null;
                                if (inputElem) {
                                    inputElem.placeholder = labelText;
                                    label.style.display = 'none';
                                }
                            });
                        }
                    });
                }
            });
        };

        /**
         * Closes the schedule tour modal if it exists
         * @method onScheduleTourCloseClick
         */
        regionRevampedConstructor.prototype.onScheduleTourCloseClick = function() {
            if (this.scheduleTourModalElem) {
                this.scheduleTourModalElem.close();
            }
        };

        /**
         * Handles a click event on the marker
         * @param {Object} community - Community object
         * @method onMarkerClick
         */
        regionRevampedConstructor.prototype.onMarkerClick = function(model) {
            this.modalData.model = model;

            if (this.flyoutModalElem) {
                var modalSlick = this.flyoutModalElem.querySelector('.slick-slider');

                this.$timeout((function() {
                    if (modalSlick && modalSlick.slick) {
                        modalSlick.slick.refresh();
                    }
                    this.flyoutModalElem.show();
                }).bind(this));
            }
        };

        /**
         * Closes the flyout modal if it exists
         * @method onFlyoutCloseClick
         */
        regionRevampedConstructor.prototype.onFlyoutCloseClick = function() {
            if (this.flyoutModalElem) {
                this.flyoutModalElem.close();
                this.modalData.model = null;
            }
        };

        /**
         * Sets the active card and scrolls to it
         * @param {String|Number} cardId - Id of card
         */
        regionRevampedConstructor.prototype.setActiveCard = function(cardId) {
            var cardElem = this.rootElem.querySelector('#card-' + cardId);

            // Set active item
            this.$timeout((function() {
                if (this.tab === 'communities') {
                    this.activeCommunity = cardId;
                } else if (this.tab === 'our-floorplans') {
                    this.activePlan = cardId;
                } else if (this.tab === 'move-in-ready') {
                    this.activeHome = cardId;
                } else if (this.tab === 'model-homes') {
                    this.activeModelHome = cardId;
                }
            }).bind(this));

            // Scroll to card
            if (cardElem) {
                cardElem.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        };

        /**
         * Handles a marker click event
         * @param {Object} markerData - Marker data object
         * @method handleMarkerClick
         */
        regionRevampedConstructor.prototype.handleMarkerClick = function(markerData) {
            var selectedModel = this.tabData.data[this.tabData.currentPage - 1].find(function(item) {
                return item.id === markerData.model.id;

            });

            // If we have the model loaded, we don't have to retrieve it again
            if (selectedModel) {
                this.onMarkerClick(selectedModel);
                this.setActiveCard(markerData.model.id);
            } else {
                // Otherwise we have to load it
                var endpoint = this.tabData.cardEndpoint + '/' + markerData.model.id;
                this.$http.get(endpoint).then((function(response) {

                    this.onMarkerClick(response.data);
                }).bind(this));
            }
        };

        /**
         * Handles a marker mouseover event
         * @param {Object} markerData - Marker data object
         * @method handleMarkerOver
         */
        regionRevampedConstructor.prototype.handleMarkerOver = function(markerData) {
            markerData.view.zIndex = 1;
        };

        /**
         * Handles a marker mouseout event
         * @param {Object} markerData - Marker data object
         * @method handleMarkerOut
         */
        regionRevampedConstructor.prototype.handleMarkerOut = function(markerData) {
            markerData.view.zIndex = null;
        };

        /**
         * Handles a mouseover event on a card
         * @param {Number} itemId - Id of item
         * @method onCardMouseover
         */
        regionRevampedConstructor.prototype.onCardMouseover = function(itemId) {
            // If the map is not loaded, don't do anything
            if (!this.map || !this.$scope.mapData.markers || !this.$scope.mapData.markers.length) {
                return;
            }

            var marker = this.$scope.mapData.markers.find(function(m) {
                return m.content.id.replace('marker-', '') == itemId;
            });

            if (marker) {
                marker.content.classList.add('reg__marker--hover');
                marker.zIndex = 1;
            }
        };

        /**
         * Handles a mouseout event on a card
         * @param {Number} itemId - Id of item
         * @method onCardMouseout
         */
        regionRevampedConstructor.prototype.onCardMouseout = function(itemId) {
            // If the map is not loaded, don't do anything
            if (!this.map || !this.$scope.mapData.markers || !this.$scope.mapData.markers.length) {
                return;
            }

            var marker = this.$scope.mapData.markers.find(function(m) {
                return m.content.id.replace('marker-', '') == itemId;
            });


            if (marker) {
                marker.content.classList.remove('reg__marker--hover');
                marker.zIndex = null;
            }
        };

        /**
         * Handles a click event on a pagination button or called
         * by other methods to load a page of data
         * @param {number} page - Page number to load
         */
        regionRevampedConstructor.prototype.onPageClick = function(page) {
            this.isLoading = true;
            this.loadCardData(page);
        };

        /**
         * Handles a click event on the next button for pagination
         * @method onNextClick
         */
        regionRevampedConstructor.prototype.onNextClick = function() {
            if (this.tabData.currentPage === this.tabData.lastPage) {
                return;
            }

            this.onPageClick(this.tabData.currentPage + 1);
        };

        /**
         * Handles a click event on the previous button for pagination
         * @method onPrevClick
         */
        regionRevampedConstructor.prototype.onPrevClick = function() {
            if (this.tabData.currentPage === 1) {
                return;
            }

            this.onPageClick(this.tabData.currentPage - 1);
        };

        /**
         * Opens a modal
         * @param {string} modalId - Id of modal
         * @param {Object} hubspotData - Hubspot data object
         * @param {string} hubspotData.formId - Hubspot form id
         * @param {string} hubspotData.portalId - Hubspot portal id
         * @param {string} hubspotData.region - Hubspot region
         * @method openModal
         */
        regionRevampedConstructor.prototype.openModal = function(modalId, hubspotData) {
            var modal = document.getElementById(modalId);

            if (!modal) {
                console.error('Modal not found', modalId);
                return;
            }

            var form = modal.querySelector('#' + modalId + '-form');

            if (!form) {
                console.error('Form not found', modalId);
                return;
            }

            if (typeof hbspt === 'undefined') {
                console.error('Hubspot not found');
                return;
            }

            hbspt.forms.create({
                portalId: hubspotData.portalId,
                formId: hubspotData.formId,
                region: hubspotData.region,
                target: '#' + modalId + '-form',
            });
            modal.showModal();
        };

        /**
         * Closes a modal
         * @param {string} modalId - Id of modal
         * @method closeModal
         */
        regionRevampedConstructor.prototype.closeModal = function(modalId) {
            var modal = document.getElementById(modalId);
            console.log('closing', modalId);
            if (modal) {
                console.log('modal found', modal);
                modal.close();
            }
        };

        return regionRevampedConstructor;
    })();

    // Dependency injection for controller
    RegionRevampedController.$inject = [
        '$scope',
        '$window',
        '$timeout',
        '$http',
        'regionStorageFactory'
    ];

    /**
     * Directive for region-revamped component
     * @returns {Object} - Directive object as defined by AngularJS
     */
    var regionRevampedDirective = function() {
        return {
            restrict: 'A',
            scope: {
                initTab: '@',
                region: '=',
                regionId: '@',
                subregionId: '@',
                subregionOptions: '=',
            },
            controller: RegionRevampedController,
            controllerAs: 'vm',
            bindToController: true,
            transclude: true,
            template: '<div ng-transclude></div>',
            link: function(scope, element, attrs, ctrl, transclude) {
                transclude(scope, function(clone, transcludedScope) {
                    transcludedScope.vm = ctrl;

                    element.empty();
                    element.append(clone);

                    transcludedScope.vm.rootElem = element[0];
                    transcludedScope.vm.scheduleTourModalElem = transcludedScope.vm.rootElem.querySelector('#scheduleTourModal');
                    transcludedScope.vm.filtersModalElem = transcludedScope.vm.rootElem.querySelector('#filtersModal');
                    transcludedScope.vm.flyoutModalElem = transcludedScope.vm.rootElem.querySelector('#flyoutModal');

                    if (transcludedScope.vm.filtersModalElem) {
                        // Listen for the modal close event so we can handle the return value
                        transcludedScope.vm.filtersModalElem.addEventListener('close', transcludedScope.vm.onFiltersModalClose, false);
                    }

                    // Listening for click events on the root element and keydown events on the document
                    // so we can capture necesary events for closing the filter options and modals
                    transcludedScope.vm.rootElem.addEventListener('click', transcludedScope.vm.onRootElementClick, false);
                    document.addEventListener('keydown', transcludedScope.vm.onDocumentKeyDown, false);

                    // Have to listen for resize to reInit slick sliders on card components so the
                    // images are sized correctly
                    window.addEventListener('resize', transcludedScope.vm.onResize, false);
                });
            }
        };
    };

    // Dependency injection for directive
    RegionRevampedModule
        .directive('regionRevamped', regionRevampedDirective);
})();
